/*
*
* Tables custom
*/

$table-head-padding: 29px 15px 27px;
$table-cell-padding: 12px 15px;
$table-condensed-cell-padding: 5px;

$table-bg: $white;
$table-bg-accent: $gray-200;
$table-bg-hover: $primary;
$table-bg-active: $table-bg-hover;

$table-border-color: $gray-3;

.table-custom {
	width: 100%;
	text-align: left;
	max-width: 100%;
	font-size: 18px;
	line-height: 1;
	text-transform: uppercase;
	font-family: $font-family-sec;
	background: $table-bg;
	border-collapse: collapse;
	border: 1px solid $table-border-color;

	th,
	td {
		color: $gray-700;
		background: $table-bg;
	}

	th {
		padding: $table-head-padding;
		font-weight: 500;
		letter-spacing: .1em;
		text-transform: uppercase;
		color: $gray-500;
		border-bottom: 1px solid $table-border-color;

		@include media-breakpoint-down(md) {
			padding-top: 18px;
			padding-bottom: 14px;
		}
	}

	td {
		padding: $table-cell-padding;
		line-height: 1;
		font-weight: 400;
		letter-spacing: .1em;
		color: $gray-800;
	}

	tfoot {
		td {
			font-weight: 700;
		}
	}
}

* + .table-custom-responsive {
	margin-top: 25px;
}

//
// Table Custom styles variants
//
.table-custom.table-custom-primary {
	thead th {
		color: $white;
		background: $primary;
		border: 0;
	}
}

.table-custom.table-custom-bordered {
	tr {
		td:first-child {
			border-left: 0;
		}
		td:last-child {
			border-right: 0;
		}
	}
	td {
		border: 1px solid $table-border-color;
	}

	tbody > tr:first-of-type > td {
		border-top: 0;
	}
}

.table-custom.table-custom-striped {
	border-bottom: 1px solid $table-border-color;
	tbody tr:nth-of-type(odd) td {
		background: transparent;
	}

	tbody tr:nth-of-type(even) td {
		background: $table-bg-accent;
	}

	tbody td {
		border: 0;
	}

	tfoot td:not(:first-child) {
		border-left: 0;
	}
}

//
// Table Custom Additional
//
.table-custom-responsive {
	@include media-breakpoint-down(md) {
		display: block;
		width: 100%;
		overflow-x: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057

		// Prevent double border on horizontal scroll due to use of `display: block;`
		&.table-bordered {
			border: 0;
		}
	}
}



//
// Table cart
//
.table-cart {
	text-align: center;

	th,
	td {
		&:first-child {
			text-align: left;
			
			// Large ≥992px
			@include media-breakpoint-up(lg) {
				padding-left: 32px;
			}
		}
	}

	tbody {

		tr {
			&:first-child td { padding-top: 24px; }
			&:last-child td { padding-bottom: 24px; }
		}
	}

	td {
		width: auto;
		min-width: auto;
		font-size: 18px;
		letter-spacing: .075em;

		&:first-child {
			width: 61%;
			min-width: 350px;

			> * {
				display: inline-block;
				vertical-align: middle;
			}

			// Large ≥992px
			@include media-breakpoint-up(lg) {
				min-width: 500px;
			}
		}

		&:not(:first-child) {
			width: 13%;
		}

		&:nth-child(2),
		&:nth-child(4) {
			min-width: 140px;
		}

		&:nth-child(3) {
			min-width: 150px;
		}

		// Large ≥992px
		@include media-breakpoint-up(lg) {
			font-size: 24px;
		}
	}
}

.table-cart-link {
	letter-spacing: .05em;
	@include link($gray-800, $primary);
}

.table-cart-figure {
	display: inline-block;
	text-align: center;
	width: 100%;
	max-width: 90px;
	background: $gray-1;

	// Large ≥992px
	@include media-breakpoint-up(lg) {
		max-width: 146px;
	}
}

.table-cart-figure+ a { margin-left: 15px; }

// Large ≥992px
@include media-breakpoint-up(lg) {
	.table-cart-figure+ a { margin-left: 30px; }
}



//
// Table Checkout
//

.table-checkout {

	tr + tr { td { border-top: 1px solid $table-border-color; } }

	td {
		padding: 20px 20px 16px;
		min-width: 150px;

		&:last-child {
			text-align: right;
		}
	}
}

// Medium ≥576px
@include media-breakpoint-up(sm) {
	.table-checkout td {
		padding: 29px 64px 25px 25px;
	}
}