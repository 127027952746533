//
// Nav custom
// 

// 
// Table of Contents:
// 
// Tabs Line 
// Tabs Corporate
//

// Corporate style

$tabs-line-active-color: $primary;

$tabs-corporate-background: transparent;
$tabs-corporate-active-color: $gray-800;
$tabs-corporate-active-background: $primary;

$tabs-color: $gray-500;
$tabs-border: $gray-3;

// Base styles
.tabs-custom {
	text-align: left;

	.nav-tabs {
		font-size: 0;
		line-height: 0;
		word-spacing: 0;
		border: 0;
		justify-content: center;

		&:before,
		&:after {
			display: none;
		}
	}

	.nav-item {
		float: none;
		margin: 0;
		border: 0;
		cursor: pointer;
		transition: .33s all ease;
	}

	.nav-link {
		margin: 0;
		border-radius: 0;
		border: 0;
		color: $tabs-color;
		font-family: $font-family-sec;
	}

	.nav-link.active {
		cursor: default;
	}
}

.tab-content > .tab-pane {
	display: block;
	visibility: hidden;
	height: 0;
	overflow: hidden;
}

.tab-content > .active {
	visibility: visible;
	height: auto;
	overflow: visible;
}

// Offsets
* + .tabs-line { margin-top: 24px; }
* + .tabs-line-big { margin-top: 18px; }
* + .tabs-line-2 { margin-top: 26px; }
* + .tabs-horizontal.tabs-corporate { margin-top: 25px; }
h1 + .tabs-line { margin-top: 22px; }
.row + .tabs-corporate { margin-top: 66px; }


//
// Tabs line
//

.tabs-line {

	.nav-link {
		padding: 10px 10px 6px;
		text-transform: uppercase;
		font-size: 20px;
		font-weight: 400;
		line-height: 1;
		letter-spacing: .075em;
		text-align: center;
		vertical-align: middle;
	}

	.nav-link-big { font-size: 24px; }

	.nav-link-small {
		font-size: 18px;
		font-weight: 500;
		letter-spacing: .025em;
	}

	* + .tab-content { margin-top: 20px; }

	@include media-breakpoint-down(sm) {

		.nav-tabs { margin-bottom: -10px; }
		.nav-item {	margin-bottom: 10px; }

		.nav-link {
			border-width: 1px 0 1px 0;
			border-style: solid;
			border-color: $tabs-border;
		}

		.nav-link:hover,
		.nav-link.active {
			color: $tabs-line-active-color;
		}

		.nav-link.active {
			border-color: $primary;
		}
	}

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		.nav-link { font-size: 24px; }
		.nav-link-big { font-size: 36px; }
		.nav-link-small { font-size: 18px; }
	}
}

.tabs-line-big {

	.button {
		min-width: 180px;
	}

	h5 + p { margin-top: 8px; }
	* + .group-md {	margin-top: 36px; }
}

.tabs-line-style-2 {

	.nav-link {	letter-spacing: .1em; }
}

.tabs-line-style-3 {
	h5 + p { margin-top: 10px; }
	* + .group-md { margin-top: 35px; }
}



//
// Tabs corporate style
//
.tabs-corporate {

	.nav-tabs {
		position: relative;
	}

	.nav-link {
		padding: 12px 0 8px;
		text-transform: uppercase;
		font-size: 18px;
		font-weight: 500;
		line-height: 1;
		letter-spacing: .1em;
		color: $tabs-color;
		background: $tabs-corporate-background;
		text-align: center;
		vertical-align: middle;
	}

	.nav-link:hover,
	.nav-link.active {
		color: $tabs-corporate-active-color;
	}

	.nav-link:hover {
		border-color: transparent;
	}

	.tab-content {
		padding: 20px 0 0;
	}

	@include media-breakpoint-down(sm) {

		.nav-tabs {
			@include group(20px, 0)
		}

		.nav-link {
			border-width: 1px;
			border-style: solid;
			border-color: transparent;
		}

		.nav-link.active {
			border-color: transparent transparent $secondary transparent;
		}
	}
}



//
// Horizontal tabs
//

@include media-breakpoint-up(md) {

	.tabs-horizontal {

		&.tabs-corporate {

			.nav-tabs {
				position: relative;
				width: 100%;
				border: 0;
				will-change: transform;
				justify-content: flex-start;
			}

			.nav-item {
				will-change: transform;
			}

			.nav-link {
				display: block;
				position: relative;
				z-index: 1;
				min-width: 188px;
				letter-spacing: .1em;
				padding: 26px 20px;
				border-width: 1px 1px 0 1px;
				border-style: solid;
				border-color: transparent;
			}

			.nav-link.active,
			.nav-link:hover {
				color: $tabs-corporate-active-color;
			}

			.nav-link.active {
				border-color: $tabs-border;
			}

			.tab-content {
				border: 1px solid $tabs-border
			}
		}

		&.tabs-line {

			.nav-tabs {
				justify-content: space-between;
				border-bottom: 3px solid $gray-3;
			}

			.nav-item {
				display: inline-block;
			}

			.nav-link {
				position: relative;
				padding: 0 0 10px 0;
				background-color: transparent;
				border: 0;

				&::after {
					content: '';
					position: absolute;
					bottom: -3px;
					left: 0;
					height: 3px;
					width: 0;
					background: $primary;
					transition: .22s ease;
				}
			}

			.nav-link-big { padding-bottom: 5px; }
			.nav-link-small { padding-bottom: 18px; }

			.nav-link.active,
			.nav-link:hover {
				color: $tabs-line-active-color;
			}

			.nav-link.active {
				&::after {
					width: 100%;
				}
			}

			* + .tab-content {
				margin-top: 30px;
			}
		}

		&.tabs-line-style-3 {
			* + .tab-content {
				margin-top: 26px;
			}
		}

		&.tabs-line-big {
			* + .tab-content {
				margin-top: 26px;
			}
		}
	}
}

@include media-breakpoint-up(md) {

	.tabs-horizontal {

		// Tabs Corporate
		&.tabs-corporate {
			.tab-content {
				padding: 45px 40px;
			}
		}
	}
}



//
// Vertical tabs
//

@include media-breakpoint-up(md) {

	.tabs-vertical {
		display: flex;
		align-items: flex-start;

		.nav-tabs {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			flex-shrink: 0;
			max-width: 50%;
		}

		.nav-item {
			border: 0;
			width: 100%;
			text-align: left;
		}

		.nav-link.active,
		.nav-link:hover {
		}

		.tab-content {
			flex-grow: 1;
		}

		// Tabs corporate
		&.tabs-line {

			.nav-tabs {
				width: auto;
				min-width: 167px;
				border: 0;
			}

			.nav-item {
				margin: 0;
			}

			.nav-link {
				position: relative;
				padding: 21px 0 17px;
				border: 0;
				overflow: hidden;
				text-align: left;
			}

			.nav-link.active,
			.nav-link:hover {
				color: $primary;
			}

			.nav-item + .nav-item {
				border-top: 1px solid $gray-3;
			}

			.tab-content {
				padding: 0 0 0 35px;
			}

			* + .tab-content { margin-top: 0; }
		}
	}
}

.tabs-custom-wrap {
	display: flex;
	align-items: center;
	min-height: 100%;
	padding: 30px 15px;
	background: $white;

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		padding-left: 30px;
		padding-right: 30px;
	}

	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
		padding: 16px 35px 24px;
	}
}

.ie-10,
.ie-11,
.ie-edge {
	.tabs-custom-wrap { height: 100%; }
}