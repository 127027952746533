//
// Formstone Stepper Plugin
// --------------------------------------------------

.stepper {
  position: relative;
  display: block;


  input[type="number"]{
    width: 100%;
    padding: 10px 42px;
    font-weight: 500;
    font-family: $font-family-sec;
    text-align: center;
    -moz-appearance: textfield;
  }

  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &-arrow{
    position: absolute;
    text-align: center;
    top: 50%;
    margin-top: -19px;
    width: 38px;
    height: 38px;
    font-size: 14px;
    line-height: 38px;
    font-weight: 400;
    font-family: $fa;
    cursor: pointer;
    color: $gray-800;
    border-radius: 0;
    transition: .3s all ease;

    &:hover{
      color: $primary;
    }

    &.up{
      right: 0;

      &:before{
        content: '\f067';
      }
    }

    &.down{
      left: 0;

      &:before{
        content: '\f068';
      }
    }
  }

  &.disabled{
    .stepper-arrow{
      pointer-events: none;
      opacity: .5;
    }
  }
}


.product-stepper {

  .stepper {
    padding-right: 32px;
  }

  input[type="number"] {
    padding: 10px 5px;
    font-size: 24px;
    max-width: 66px;
  }

  .stepper-arrow {
    margin-top: 0;
    top: auto;
    right: 0;
    width: 28px;
    height: 28px;
    line-height: 28px;
    background: $form-input-background;

    &.up{
      top: 0;
    }

    &.down {
      left: auto;
      bottom: 0;
    }
  }
}


.table-cart-stepper {
  max-width: 114px;
  margin-left: auto;
  margin-right: auto;

  input[type="number"]{
    font-size: 18px;
    line-height: 1;
    letter-spacing: .1em;
    padding-bottom: 6px;
    background: $white;
    border-color: $white;
  }

  .stepper-arrow {
    line-height: 36px;
    border-radius: 4px;
    border: 1px solid $gray-3;
  }
}