/*
*
* Swiper
*/

.swiper-container {
	display: flex;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	height: auto;
	width: 100%;
	/* Fix of Webkit flickering */
	z-index: 1;
}

.swiper-container-no-flexbox .swiper-slide {
	float: left;
}

.swiper-container-vertical > .swiper-wrapper {
	flex-direction: column;
}

.swiper-wrapper {
	position: relative;
	z-index: 1;
	display: flex;
	align-self: stretch;
	align-items: stretch;
	width: 100%;
	height: auto;
	min-height: inherit;
	transition-property: transform;
	box-sizing: content-box;
}

.swiper-container {
	flex-shrink: 0;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
	transform: translate3d(0, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
	flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
	transition-timing-function: ease-out;
	margin: 0 auto;
}

/* a11y */
.swiper-container .swiper-notification {
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	opacity: 0;
	z-index: -1000;
}

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
	touch-action: pan-y;
}

.swiper-wp8-vertical {
	touch-action: pan-x;
}

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper {
	/* Windows 8 IE 10 fix */
}

// Swiper fade effect
//

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
	transition-timing-function: ease-out;
}

.swiper-container-fade .swiper-slide {
	pointer-events: none;
}

.swiper-container-fade .swiper-slide .swiper-slide {
	pointer-events: none;
}

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
	pointer-events: auto;
}

// Swiper preloader
//

.swiper-lazy-preloader {
	width: 42px;
	height: 42px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -21px;
	margin-top: -21px;
	z-index: 10;
	transform-origin: 50%;
	animation: swiper-preloader-spin 1s steps(12, end) infinite;
}

.swiper-lazy-preloader:after {
	display: block;
	content: "";
	width: 100%;
	height: 100%;
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
	background-position: 50%;
	background-size: 100%;
	background-repeat: no-repeat;
}

.swiper-lazy-preloader-white:after {
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}

@keyframes swiper-preloader-spin {
	100% {
		transform: rotate(360deg);
	}
}

.swiper-slide > .vide__body,
.swiper-slide > .parallax_cnt {
	height: 100%;
}

//
// Swiper navigation
//

.swiper-button-prev,
.swiper-button-next {
	text-align: center;
	position: absolute;
	display: none;
	top: 50%;
	width: 47px;
	height: 47px;
	line-height: 47px;
	background: transparent;
	transform: translateY(-50%);
	will-change: transform;
	transition: all .3s ease-in-out;
	cursor: pointer;
	z-index: 10;

	&::before {
		display: inline-block;
		content: '';
		width: 0;
		height: 0;
		border-style: solid;
		border-color: transparent;
		transform: none;
		transition: inherit;
	}

	&::after {
		position: absolute;
		content: '';
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: $white;
		transition: inherit;
		z-index: -1;
	}

	&:hover {
		&::before {transform: scale(1.4);}
		
		&::after {
			border-radius: 50%;
			background: $secondary;
		}
	}

	@include media-breakpoint-up(md) {
		display: block;
	}

	// Disabled button
	&.swiper-button-disabled {
		opacity: 0.35;
		cursor: auto;
		pointer-events: none;
	}
}

.swiper-button-prev {
	left: 15px;

	&:before {
		margin-left: -4px;
		border-width: 5px 8px 5px 0;
		border-right-color: $secondary;
	}

	&:hover {
	  &::before {border-right-color: $white;}
		&::after {transform: rotate(-360deg);}
	}

	// Mega large ≥1600px
	@include media-breakpoint-up(xxl) {
	  left: 75px;
	}
}

.swiper-button-next {
	right: 15px;

	&:before {
		margin-right: -4px;
		border-width: 5px 0 5px 8px;
		border-left-color: $secondary;
	}

	&:hover {
	  &::before {border-left-color: $white;}
		&::after {transform: rotate(360deg);}
	}

	// Mega large ≥1600px
	@include media-breakpoint-up(xxl) {
	  right: 75px;
	}
}

.swiper-slider.swiper-container-rtl {
	.swiper-wrapper {

	}
	.swiper-button-prev {
		&::before {
			content: "\f061";
		}
	}

	.swiper-button-next {
		&::before {
			content: "\f060";
		}
	}
}

// Swiper pagination
//

.swiper-pagination {
	position: absolute;
	display: block;
	text-align: center;
	transform: translate3d(0, 0, 0);
	transition: .3s;
	z-index: 10;

	&.swiper-pagination-hidden {
		opacity: 0;
	}

	&.swiper-pagination-clickable .swiper-pagination-bullet {
		cursor: pointer;
	}
}

.swiper-pagination-bullet {
	text-align: center;
	display: inline-block;
	position: relative;
	width: 14px;
	height: 14px;
	border: none;
	border-radius: 0;
	outline: none;
	transition: .2s;
	background: rgba($secondary, .6);

	&:hover,
	&:focus {	background: $secondary;	}
}

.swiper-pagination-bullet-active {
	background: $primary;
}



// Pagination Style 2
.swiper-pagination-style-2 {

	.swiper-pagination {
    font-size: 0;
    line-height: 0;
  }

	.swiper-pagination-bullet {
		background: transparent;

		&::before,
		&::after {
			position: absolute;
			content: '';
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
      pointer-events: none;
		}

		&::before {
			background: rgba($secondary, .6);
			transition: transform 0.3s ease, opacity 0.3s ease, background-color 0.3s ease;
		}

		&::after {
			opacity: 0;
			background: $primary;
			transform: translateY(-200%);
			transition: transform 0.3s ease, opacity 0.3s ease, visibility 0s 0.3s;
		}

		&:hover,
		&:focus {
			background: transparent;

			&::before {
				background: $secondary;
			}
		}
	}

	.swiper-pagination-bullet-active {
		background: transparent;

		&::after {
			opacity: 1;
			visibility: visible;
			transform: translateY(0%);
			transition: transform 0.3s ease, opacity 0.3s ease;
		}

		&::before {
			opacity: 0;
			visibility: hidden;
			transform: translateY(200%);
		}
	}
}

// Dark pagination
.swiper-pagination-black {
	.swiper-pagination-bullet {
		background: rgba(0, 0, 0, .6);

		&.swiper-pagination-bullet-active {
			background: rgba(0, 0, 0, 1);
		}
	}
}

// Swiper pagination orientation
//

// Vertical pagination
.swiper-container-vertical {
	> .swiper-pagination {
		top: 50%;
		right: 10px;
		transform: translate3d(0px, -50%, 0);

		.swiper-pagination-bullet {
			margin: 8px 0;
			display: block;
		}
	}

	&.swiper-pagination-style-2 {
		.swiper-pagination-bullet {

			&::after {
				transform: translate3d(-200%, 0, 0);
			}
		}

		.swiper-pagination-bullet-active {
			&::before {
				transform: translate3d(200%, 0, 0);
			}

			&::after {
				transform: translate3d(0%, 0, 0);
			}
		}
	}
}

// Horizontal pagination
.swiper-container-horizontal {
	> .swiper-pagination {
	bottom: 30px;
	left: 0;
	width: 100%;

	.swiper-pagination-bullet {
		margin: 0 10px;
	}
}
}

//
// Swiper slide styles
//
.swiper-slide {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	white-space: nowrap;
	background-position: 50% 50%;
}

.swiper-slide-caption {
	width: 100%;
	white-space: normal;

	// Medium <768px
	@include media-breakpoint-down(sm) {
		padding-bottom: 80px;
	}
}



//
// Swiper Slider styles
//

// Swiper Slider Classic
.swiper-slider-classic {
	max-height: (740 / 1920) * 100vw;
	min-height: calc(100vh - 56px);

  &.swiper-container-horizontal {
    max-height: none;
  }

  @media (max-height: 420px) {
		min-height: 260px;

		p {display: none;}
	}

	.swiper-pagination-bullet {
		position: relative;
		overflow: hidden;
		display: inline-block;
		width: 29px;
		height: 19px;
		border-radius: 0;
		background: transparent;

		&::before,
		&::after {
			position: absolute;
			display: inline-block;
			content: '';
			top: 8px;
			left: 0;
			height: 3px;
			width: 29px;
			transition: none;
			opacity: 1;
		}

		&::before {
			background: $white;

			// Mega large ≥1600px
			@include media-breakpoint-up(xxl) {
				background: $gray-20;
			}
		}
		
		&::after {
			transform: translateX(-100%);
			animation: pagOutX .3s;
			background: $secondary;
		}

		&:hover,
		&.swiper-pagination-bullet-active {
			background: transparent;
			&::after {
				animation: pagInX .3s forwards;
			}
		}
	}

	.swiper-pagination {
		position: static;
		transform: none;

		.swiper-pagination-bullet {
			margin: 0 16px;
		}
	}

	h1 {
		letter-spacing: .05em;
	}

	* + p { margin-top: 14px; }
	* + .button { margin-top: 25px; }

	.swiper-slide-1 { background-position-x: 40%; }

	// Large ≥768px
	@include media-breakpoint-up(md) {
    min-height: 460px;

    &.swiper-container-vertical {
      .swiper-pagination-bullet {
        display: block;
      }

      .swiper-pagination {
        .swiper-pagination-bullet {
          margin: 0;
        }
      }

      .swiper-pagination__module {
        top: 50%;
        right: 20px;
        bottom: auto;
        left: auto;
        max-width: 29px;
        transform: translateY(-50%);
      }
    }

		.swiper-slide-caption {
			padding-left: 70px;
			padding-right: 70px;
		}

		.swiper-slide-1 { background-position-x: 50%; }
		.swiper-slide-custom { padding-left: 0; }

		h1 { font-size: 60px; }
	}

	// Large ≥992px
	@include media-breakpoint-up(lg) {

		.swiper-slide-custom { padding-left: 70px; }

		h1 {
			font-size: 80px;
		}
	}

	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {

    &.swiper-container-horizontal { min-height: (740 / 1920) * 100vw; }

		h1 {
			font-size: 100px;
		}
	}

	// Mega large ≥1600px
	@include media-breakpoint-up(xxl) {
		max-width: 1920px;
		padding: 0 75px;
	}
}

.swiper-slider-classic-2 {
	.swiper-pagination__fraction-index { color: $primary; }
	.swiper-pagination-bullet::after { background: $primary; }

	.context-dark {
		.button.button-ujarak {
			&::before { background: $primary;	}

			&:hover,
			&:active { border-color: $primary; }
		}
	}

	.button {	min-width: 175px; }

	.swiper-title-2 {
		letter-spacing: .025em;
		font-weight: 400;
	}

	h2 { letter-spacing: .075em; }

	.swiper-slide-custom {
		* + .button { margin-top: 20px; }
	}

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		.swiper-slide-custom {
			* + .button { margin-top: 30px; }
		}
	}
	
	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {

		.swiper-title-2 { font-size: 110px; }
	}
}

.swiper-pagination__module {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	align-items: center;
	justify-content: center;
  font-size: 0;
  line-height: 0;
	position: absolute;
	bottom: 25px;
	width: 100%;
	z-index: 1;
}

.swiper-pagination__fraction {
	display: none;
	font-size: 18px;
	line-height: 1;
	font-weight: 500;
	letter-spacing: 0;
	font-family: $font-family-sans-serif-2;
	color: $gray-500;
	transform: rotate(-90deg);
}

.swiper-pagination__fraction-index {
	color: $secondary;
}

.swiper-pagination__divider {
	display: none;
	margin: 30px 0 8px;
	width: 1px;
	height: 176px;
	background: $gray-3;
}

// Mega large ≥1600px
@include media-breakpoint-up(xxl) {
	.swiper-pagination__fraction {
		display: inline-block;
	}
	.swiper-pagination__divider {
		display: inline-block;
	}
}



// Swiper Slider Modern
.swiper-slider-modern {
	min-height: (850 / 1920) * 100vw;

	.swiper-slide {
		background-position: 0 50%;
	}

	.swiper-slide-caption {
		padding-top: 60px;
	}

	.swiper-button-prev,
	.swiper-button-next {
		transform: none;
		top: 51%;
	}

	.slider-modern-box {
		max-width: 240px;
	}

	.slider-modern-title {
		line-height: 1.115;
		font-weight: 300;
		letter-spacing: .025em;

		span {display: inline-block;}
	}

	.button {
		min-width: auto;
		padding-left: 40px;
		padding-right: 40px;
	}

	p {
		font-size: 16px;
		line-height: 1.625;
		font-weight: 500;
		letter-spacing: 0;
	}

	* + p {margin-top: 10px;}
	* + .button-wrap {margin-top: 30px;}

	// Small <576px
	@include media-breakpoint-down(xs) {
		.swiper-slide {
			background-position: 30% 50%;
		}
	}

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		.swiper-slide-caption {
			padding-top: 70px;
			padding-bottom: 70px;
		}

		.slider-modern-box {
			margin-left: 60px;
		}

		.swiper-pagination {display: none;}
	}

	// Large ≥992px
	@include media-breakpoint-up(lg) {
		.swiper-slide-caption {
			padding-top: 80px;
			padding-bottom: 80px;
		}

		.slider-modern-box {
			margin-left: 80px;
			max-width: 320px;
		}
	}

	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
		.swiper-slide-caption {
			padding-top: 200px;
			padding-bottom: 96px;
		}

		.slider-modern-box {margin-left: 90px;}

		* + .button-wrap {margin-top: 40px;}
	}

	// Mega large ≥1600px
	@include media-breakpoint-up(xxl) {
		.slider-modern-box {margin-left: 24px;}
	}
}



// Swiper Slider Creative
.swiper-slider-creative {
	min-height: (661 / 1920) * 100vw;

	.swiper-slider-text {
		font-size: 16px;
		line-height: 1.5;
		font-weight: 500;
		letter-spacing: 0;
	}

	.button { min-width: 175px; }

	h1 { letter-spacing: .025em; }

	hr {
		max-width: 78%;
		margin-left: 0;
		border-color: rgba($white, .48);
	}

	h4 {
		font-weight: 300;
		letter-spacing: .05em;
	}

	* + hr,
	* + .swiper-slider-text { margin-top: 12px; }
	* + .swiper-creative-decor { margin-top: 16px; }
	h1 + h4 { margin-top: 2px; }
  * + .button-wrap { margin-top: 30px; }
	.swiper-slider-text + .button,
	.swiper-slider-text + .button-wrap { margin-top: 30px; }

  .swiper-pagination {
		font-size: 0;
		line-height: 0;
		counter-reset: span;
		bottom: 20px;
	}

	.swiper-pagination-bullet {
		width: 35px;
		height: 23px;
		padding: 10px 0;
		background: transparent;

		&::after {
			display: inline-block;
			position: absolute;
			content: '';
			left: 50%;
			top: 50%;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background: transparent;
			transform: scale(0, 0) translate3d(-50%, -50%, 0) rotate(.1deg);
			will-change: transform;
			transition: inherit;
			transform-origin: 0 0;
		}

		span {
			display: inline-block;
			height: 3px;
			width: 100%;
			background: $white;
			transition: all .3s ease;
		}
	}

	.swiper-pagination-bullet:hover,
	.swiper-pagination-bullet-active {
		&::after {
			background: $white;
			transform: scale(1, 1) translate3d(-50%, -50%, 0) rotate(.1deg);
			transition-delay: .1s;
		}

		span { width: 0; }
	}

  // Small <576px
  @include media-breakpoint-down(xs) {
    .swiper-slide {
      background-position-x: 35%;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($black, .15);
        z-index: -1;
      }
    }
  }

  // Medium 768  ≤ 991 Large
  @include media-breakpoint-between(md, md) {
    h4 { font-size: 30px; }
  }

  // Small 576  ≤ 767 Medium
  @include media-breakpoint-between(sm, sm) {
    .swiper-slide-2 { background-position-x: 60%; }
    .swiper-slide-3 { background-position-x: 55%; }
  }
	
	// Medium ≥768px
	@include media-breakpoint-up(md) {
		.swiper-pagination { bottom: 30px; }
	}
  
  // Large 992  ≤ 1200 Extra Large
  @include media-breakpoint-between(md, lg) {
    .swiper-slide-2 { background-position-x: 70%; }
    .swiper-slide-3 { background-position-x: 60%; }
  }

  // Large ≥992px
  @include media-breakpoint-up(lg) {

    * + .button,
    * + .button-wrap { margin-top: 40px; }
  }

	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {

		h1 { font-size: 110px; }
	}

	// Mega large ≥1600px
	@include media-breakpoint-up(xxl) {
		.swiper-pagination {
			top: 50%;
			right: 134px;
			bottom: auto;
			left: auto;
			width: auto;
			transform: translate3d(0, -50%, 0);

			.swiper-pagination-bullet { margin: 4px 0; }
		}

		.swiper-pagination-bullet {
			display: block;
			width: 21px;
			height: 21px;

			&::before {
				display: inline-block;
				position: absolute;
				content: counter(span, decimal);
				counter-increment: span;
				top: 60%;
				left: -22px;
				font-size: 24px;
				line-height: 1;
				letter-spacing: .05em;
				font-weight: 500;
				font-family: $font-family-sec;
				color: $white;
				opacity: 0;
				visibility: hidden;
				transform: translate3d(-20px, -50%, 0);
				will-change: transform;
				transition: inherit;
			}

			&::after {
				left: 1px;
				width: 5px;
				height: 5px;
				transform: scale(0, 0) translate3d(0, -50%, 0) rotate(.1deg);
				transform-origin: 50% 0;
			}

			span {
				height: 1px;
			}
		}

		.swiper-pagination-bullet:hover,
		.swiper-pagination-bullet-active {

			&::after {
				transform: scale(1, 1) translate3d(0, -50%, 0) rotate(.1deg);
				transition-delay: 0s;
			}

			span {
				width: 100%;
				transform: translate3d(24px, 0, 0);
			}
		}

		.swiper-pagination-bullet-active {

			&::before {
				opacity: 1;
				visibility: visible;
				transform: translate3d(0, -50%, 0);
			}

			span { width: 340%; }
      
      &:hover {
        span { width: 340%; }
      }
		}
	}
}



// Swiper Slider Corporate
.swiper-slider-corporate {
  min-height: calc(100vh - 56px);

  &.swiper-container-horizontal {
    .swiper-pagination {
      .swiper-pagination-bullet {
        margin: 0 12px;
      }
    }
  }

  &.swiper-container-vertical {
    max-height: (678 / 1920) * 100vw;

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
    }
  }

  @media (max-height: 370px) {
    min-height: 310px;
  }

	.swiper-pagination-bullet {

		&::before { background: rgba($white, .33); }
		&::after { background: $secondary; }

		&:hover {
			&::before { background: rgba($white, .7); }
		}
	}

	.button {	min-width: 180px; }

	h6 {
		font-weight: 400;
		letter-spacing: .15em;
		color: $primary;
	}

	h6 + h2 { margin-top: 16px; }
	* + .button-wrap { margin-top: 20px; }

  // Medium ≥768px
  @include media-breakpoint-up(md) {
    min-height: 370px;

    &.swiper-container-vertical { min-height: 400px; }

    h6 + h2 { margin-top: 28px; }
    * + .button-wrap { margin-top: 30px; }
  }

  // Large ≥992px
  @include media-breakpoint-up(lg) {

    &.swiper-container-horizontal { min-height: 400px; }

    &.swiper-container-vertical {
      .swiper-pagination { right: 6%; }
    }
  }

  // Extra large ≥1200px
  @include media-breakpoint-up(xl) {
    &.swiper-container-horizontal {
      min-height: (678 / 1920) * 100vw;
    }
  }

  @include media-breakpoint-up(xxl) {
    &.swiper-container-vertical {
      .swiper-pagination {
        top: 43%;
        right: 11.5%;
        transform: none;
      }
    }
  }
}



// Swiper Slider Tara
.swiper-slider-tara {
	min-height: (679 / 1920) * 100vw;

	&.swiper-container-horizontal {
		> .swiper-pagination {
			.swiper-pagination-bullet {
				margin: 0 6px;
			}
		}
	}

	.swiper-pagination {
		bottom: 0;
		left: 50%;
		transform: translate3d(-50%,0 ,0);
		width: auto;
		min-width: 155px;
		font-size: 0;
		line-height: 0;
		padding: 17px 20px 10px;
		background: $white;
	}

	.swiper-pagination-bullet {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: transparent;
		border: 3px solid $gray-200;
		transition: all .1s ease-in-out;

		&::before,
		&::after {
			position: absolute;
			content: '';
			opacity: 0;
			border-radius: inherit;
		}

		&::before {
			top: 50%;
			left: 50%;
			width: 6px;
			height: 6px;
			background: $primary;
			transform: translate3d(-50%, -400%, 0);
			transition: all .2s ease;
		}
		
		&::after {
		  top: -3px;
		  right: -3px;
		  bottom: -3px;
		  left: -3px;
			border: 3px solid $secondary;
			transform: scale(1.6);
			transition: all .3s ease;
		}

		&-active,
		&:hover {
		  &::before {
				opacity: 1;
		    transform: translate3d(-50%, -50%, 0);
		  }
		}
		
		&-active {
			border-color: transparent;

			&::after {
				opacity: 1;
				transform: none;
			}
		}
	}

	.swiper-title { font-weight: 300;	}

	.swiper-subtitle {
		font-weight: 400;
		color: $primary;
	}

	.button { min-width: 176px;	}

	.button.button-ujarak:not([class*='button-primary']) {
		&::before { background: $primary;	}

		&:hover,
		&:active { border-color: $primary; }
	}

	* + .swiper-title {	margin-top: 16px; }
	* + .button,
	* + .button-wrap {	margin-top: 20px; }

	// Small <576px
	@include media-breakpoint-down(xs) {

		.swiper-slide {

			&::before {
				position: absolute;
				content: '';
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: rgba($white, .2);
				pointer-events: none;
			}
		}
	}

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		.swiper-pagination {
			right: 0;
			left: auto;
			transform: none;
			padding-bottom: 17px;
		}
	}

	// Large ≥992px
	@include media-breakpoint-up(lg) {

		.swiper-title { line-height: 1.2; }
		.swiper-subtitle { letter-spacing: .15em; }

		* + .swiper-title {	margin-top: 22px; }
	}
}