//
// Panel custom styles
// 

// Light
//
$accordion-corporate-color: $gray-800;
$accordion-corporate-background: $white;
$accordion-corporate-border: #dedede;
$accordion-corporate-content-color: $body-color;
$accordion-corporate-arrow-color: $gray-800;
$accordion-corporate-arrow-background: $primary;
$accordion-corporate-shadow: -1px 2px 5px 0px rgba(#444953, 0.12);
$accordion-corporate-border-radius: 6px;
$accordion-corporate-header-padding: 33px 72px 29px 30px	;
$accordion-corporate-content-padding: 25px 30px 30px nth($accordion-corporate-header-padding, 4);

// Panel groups
.card-group-custom {
	margin-bottom: 0;

	.card-header + .collapse > .card-body,
	.card-header + .collapse > .list-group {
		border-top: 0;
	}

	.card + .card {
		margin-top: 0;
	}
}

.card-group-custom.card-group-corporate {

	.card + .card {
		margin-top: 15px;
	}
}

.card-custom {
	display: block;
	margin: 0;
	background: inherit;
	border: 0;
	border-radius: 0;
	box-shadow: none;
	text-align: left;

	a {
		display: block;
	}

	// Panel heading
	.card-header {
		padding: 0;
		border-bottom: 0;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}

	// Panel contents
	.card-body {
		padding: 0;
	}
}

* + .card-group-custom { margin-top: 20px; }

// Panel corporate
.card-corporate {
	text-align: left;
	border: 1px solid $gray-3;

	.card-title {
		margin-bottom: 0;
	}

	.card-title a,
	.card-body {
		background: $accordion-corporate-background;
	}

	.card-title a {
		position: relative;
		z-index: 1;
		padding: $accordion-corporate-header-padding;
		font-size: 20px;
		line-height: 1;
		font-weight: 400;
		letter-spacing: .05em;
		font-family: $font-family-sec;
		text-transform: uppercase;
		color: $accordion-corporate-color;
		border-bottom: 1px solid $gray-3;
		transition: all .3s ease;
		
		&:hover {
		  color: $primary;
		}

		&.collapsed {
			border-color: transparent;
		}

		// Open state
		.card-arrow {
			&:after {
				opacity: 0;
				visibility: hidden;
			}
		}

		// Collapsed state
		&.collapsed {
			border-radius: 0;
			border-bottom-width: 0;

			.card-arrow {

				&:after {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}

	// Panel arrow
	.card-arrow {
		display: inline-block;
		position: absolute;
		top: 50%;
		right: 10px;
		z-index: 2;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		border: 4px solid $primary;
		transform: translate3d(0, -50%, 0);
		transition: .33s all ease;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 4;
			transform: translate(-50%, -50%);
			background: $accordion-corporate-arrow-color;
		}

		&::before {
			width: 15px;
			height: 3px;
		}

		&::after {
			width: 3px;
			height: 15px;
		}
	}

	.collapse {
		position: relative;
		z-index: 1;
		color: $accordion-corporate-content-color;
		border-radius: 0;
	}

	.card-body {
		padding: $accordion-corporate-content-padding;
		color: $gray-500;
	}

	// Small <576px
	@include media-breakpoint-down(xs) {
		.card-body {
			padding-right: 15px;
		}
	}

	// Media queries
	@include media-breakpoint-down(sm) {
		.card-title a,
		.card-body {
			padding-left: 15px;
		}

		.card-body {
			padding-top: 15px;
			padding-bottom: 15px;
		}

		.card-title a {
			padding-top: 23px;
			padding-bottom: 17px;
		}
	}

	@include media-breakpoint-up(md) {

		.card-arrow {
			right: 18px;
		}

		.card-title a {
			font-size: 24px;
			padding-right: 60px;
		}
	}
}

.card-corporate-2 {
	.card-arrow {
		border-color: $secondary;

		&::before,
		&::after {
			background: $primary;
		}
	}
}