//
// Slick carousel
// --------------------------------------------------

// Base styles
// --------------------------------------------------

$slick-font-path: "./fonts/" !default;
$slick-font-family: $font-family-base !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f237" !default;
$slick-next-character: "\f238" !default;
$slick-dot-character: "•" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 0.7 !default;
$slick-opacity-not-active: 0.7 !default;

.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;

  [dir="rtl"] & {
    float: right;
  }
  img {
    display: inline-block;
  }
  &:focus {
    outline:none;
  }
  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

@function slick-image-url($url) {
  @if function-exists(image-url) {
    @return image-url($url);
  } @else {
    @return url($slick-loader-path + $url);
  }
}

@function slick-font-url($url) {
  @if function-exists(font-url) {
    @return font-url($url);
  } @else {
    @return url($slick-font-path + $url);
  }
}

// Appearance styles
// --------------------------------------------------

.slick-list {
  .slick-loading & {
    background: #fff slick-image-url("../images/ajax-loader.gif") center center no-repeat;
  }
}

/* Icons */
@if $slick-font-family == "slick" {
  @font-face {
    font-family: "slick";
    src: slick-font-url("slick.eot");
    src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
    font-weight: normal;
    font-style: normal;
  }
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-size: 24px;
  cursor: pointer;
  background: $primary;
  color: $white;
  top: 50%;
  margin-top: -10px \9
; /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 9;

  &:hover {
    outline: none;
    color: $primary;
    background-color: $white;
    &:before {

      //color: $primary;
    }
  }
  &.slick-disabled {
    background-color: $gray-200;
  }
}

.slick-prev:before, .slick-next:before {
  font-family: $mdi;
  //color: $slick-arrow-color;
  opacity: $slick-opacity-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -15px;
  [dir="rtl"] & {
    left: auto;
    right: 0;
  }
  &:before {
    content: $slick-prev-character;
    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: -15px;
  [dir="rtl"] & {
    left: 0;
    right: auto;
  }
  &:before {
    content: $slick-next-character;
    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}

/* Dots */

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0;
      font-size: 0;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 20px;
        height: 20px;
        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}

.carousel-parent {
  margin-bottom: 7px;
}

.child-carousel {

  .slick-list {
    margin-left: -6px;
    margin-right: -6px;
  }

  .slick-slide {
    cursor:pointer;
    margin: 0 6px;

    img {
      width: 100%;
    }
  }

  .slick-product-figure {
    margin-top: 5px;
    margin-bottom: 5px;

    &:hover {
      background: rgba($gray-1, .5);
      img { transform: scale(1.1); }
    }
  }

  .slick-current .slick-product-figure {
    background: transparent;
    box-shadow: 0 0 6px 0 rgba($black, 0.17);
  }

  // Medium ≥768px
  @include media-breakpoint-up(md) {

    .slick-slide { max-width: 100%; }
  }
}

//slick vertical

.slick-product {

  // Medium 768  ≤ 991 Large
  @include media-breakpoint-between(md, md) {

    display: flex;
    margin-left: -6px;
    margin-right: -6px;

    .carousel-parent,
    .child-carousel {
      padding-left: 6px;
      padding-right: 6px;
    }

    .carousel-parent {
      width: 74%;
      margin-bottom: 0;
    }

    .child-carousel {
      width: 26%;
      display: flex;
      align-content: stretch;

      .slick-list {
        display: flex;
        margin-left: 0;
        margin-right: 0;
      }

      .slick-slide {
        width: calc(100% - 10px) !important;
        margin-left: 5px;
        margin-right: 5px;
      }
    }

    .slick-arrow {
      right: auto;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .slick-prev {
      top: -10px;

      &::before {
        content: '\f239';
      }
    }

    .slick-next {
      top: auto;
      bottom: -10px;

      &::before {
        content: '\f236';
      }
    }
  }

  // Extra large ≥992px
  @include media-breakpoint-up(lg) {
    margin-right: 25px;
  }

  // Extra large ≥1200px
  @include media-breakpoint-up(xl) {
    margin-right: 38px;
  }
}

//custom nav
.slick-custom-nav {
  .slick-prev,
  .slick-next {
    background-color: transparent;
    font-size: 60px;
  }

  .slick-prev {
    left: -30px;
  }

  .slick-next {
    right: -20px;
  }

  // Mega large ≥1600px
  @include media-breakpoint-up(xxl) {

    .slick-prev {
      left: -50px;
    }

    .slick-next {
      right: -40px;
    }
  }
}

// Slick Product Figure

.slick-product-figure {
  position: relative;
  overflow: hidden;
  background: $gray-1;
  transition: all .3s ease;

  img {
    width: 100%;
    transform: none;
    transition: inherit;
  }
}