/*
*
* Footers
*/

//
// Footer Classic
// Box Footer
// Footer Social List
// Footer Modern
// Footer Corporate
// Footer Minimal
//



//
// Footer Classic
//

.footer-classic {
	color: rgba($white, .3);
	background: $white;

	#{headings()} {
		color: rgba($white, .15);
	}

	.contact-classic-title {
		color: $primary;
	}

	.contact-classic-link  {
		color: $white;
	}
}

.footer-bottom-panel {
	padding-top: 30px;
	padding-bottom: 30px;
}

.footer-classic-2 {
	.box-footer { background: $blue;}
	.footer-list-category a:hover {
		color: $primary;

		span {
			&::before { border-right-color: $primary; }
			&::after { background-color: $primary; }
		}
	}
}



//
// Box Footer
//
.box-footer {
	text-align: left;
	min-height: 100%;
	padding: 40px 15px;
	letter-spacing: .025em;
	background: $gray-15;

	a {
		@include link($white, $secondary);
	}

	.form-wrap + * { margin-top: 12px; }
	* + .rd-form { margin-top: 20px; }

	.form-validation {
		color: $white;
	}

	.form-label {
		top: 32px;
		font-size: 16px;
		color: $white;
	}

	.form-input {
		font-size: 16px;
		padding-top: 18px;
		padding-bottom: 18px;
		border: none;
		color: $white;
		background: rgba($white, .06);
	}

	textarea.form-input {
		height: 160px;
	}

	.button.button-ujarak {
		&::before {
			background: $white;
		}

		&:hover,
		&:active {
			color: $gray-800;
			border-color: $white;
		}
	}
}

.box-footer-small {
	padding-top: 45px;
}

.contact-classic {
	.unit-left {
		min-width: 45px;
	}

	a {display: inline-block;}
}

.contact-classic-item {
	padding: 20px 0;
	border-bottom: 1px solid rgba($white, .15);
}

.contact-classic-title {
	margin-top: 2px;
	letter-spacing: .025em;
	color: $primary;
}

.contact-classic-link  {
	color: $white;
}

* + .contact-classic {	margin-top: 3px; }
.footer-brand + p {	margin-top: 22px; }

@media (min-width: 480px) {
	.box-footer {
		padding: 40px 30px;
	}
}

// Large ≥992px
@include media-breakpoint-up(lg) {
	.box-footer {
		* + .rd-form { margin-top: 8px; }
	}
}

@media (min-width: 1200px) and (max-width: 1399px) {
	.box-footer {
		padding-left: 20px;
		padding-right: 20px;
	}
}

@media (min-width: 1800px) {
	.box-footer {
		padding: 60px 60px 50px;
	}
}

// Box Footer 2
.box-footer-2 {
	text-align: left;
	padding: 30px 20px;
	min-height: 100%;
	background: $white;
	border: 1px solid $gray-3;

	.footer-contacts {
		.icon { color: $primary; }
	}
}

.box-footer-form {
	padding: 30px 20px;
	background: $primary;

	.form-input {
		min-height: 50px;
		padding-top: 12px;
		padding-bottom: 12px;
	}

	.form-label {
		top: 25px;
	}

	textarea.form-input {
		height: 116px;
		max-height: round(116px * 1.7);
	}

	.button {
		min-width: auto;
		padding-left: 20px;
		padding-right: 20px;
	}

	.form-wrap + * { margin-top: 10px; }
}

.ios,
.iphone,
.ipad,
.mac-os {
	.box-footer-form .form-label {
		top: 13px;
		transform: none;
	}
}

.box-footer-2-title {
	letter-spacing: .025em;
}

.box-footer-2-text {
	letter-spacing: .025em;
	color: $gray-500;
}

* + .box-footer-2-text { margin-top: 8px; }

// Medium ≥768px
@include media-breakpoint-up(md) {
	.box-footer-2 { border: none; }
}

// Large ≥992px
@include media-breakpoint-up(lg) {
	.box-footer-2 {
		padding: 35px 30px 25px;

		.footer-contacts {
			li + li { margin-top: 12px; }
		}
	}

	.box-footer-form { padding: 35px 25px; }

	.box-footer-2-text + .footer-contacts { margin-top: 20px; }
}

// Extra large ≥1200px
@include media-breakpoint-up(xl) {
	.box-footer-2-text + .footer-contacts { margin-top: 25px; }
} 



//
// Footer Social List
//
.footer-social-list {

	.icon {
		@include link($white, $primary);

		position: relative;
		text-align: center;
		width: 46px;
		height: 46px;
		font-size: 20px;
		line-height: 46px;
		border-radius: 50%;
		box-shadow: inset 0 0 0 4px $gray-22;
		transition: color .3s;
		z-index: 1;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 2px;
			border-radius: 50%;
			background: $gray-22;
			pointer-events: none;
			transition: transform 0.2s, opacity 0.3s;
			z-index: -1;
		}

		&:hover {
			&:after {
				opacity: 0;
				transform: scale(1.3);
			}
		}
	}
}

* + .footer-social-list { margin-top: 26px; }

@media (min-width: 480px) {
	.footer-social-list {
		.icon {
			width: 56px;
			height: 56px;
			font-size: 25px;
			line-height: 56px;
		}
	}
}



//
// Footer List Category
//
.footer-list-category {
	li {
		font-weight: 400;
		border-bottom: 1px solid rgba($white, .15);

		&:first-child {
			border-top: 1px solid rgba($white, .15);
		}
	}

	a {
		position: relative;
		display: block;
		padding: 22px 40px 20px 2px;
		transition: color .3s ease-in-out, all .3s ease;

		@include link($white, $primary);

		span {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 38%;
			right: 0;
			font-size: 0;
			line-height: 0;
			padding-left: 6px;
			padding-right: 6px;

			&::before,
			&::after {
				display: inline-block;
				vertical-align: middle;
				content: '';
				transition: all .2s ease-in-out;
			}

			&::before {
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 6px 10px 6px 0;
				border-color: transparent rgba($white, .1) transparent transparent;
			}

			&::after {
				width: 0;
				height: 2px;
				background-color: rgba($white, .1);
			}
		}

		&:hover {
			letter-spacing: .1em;

		  span {
				&::before {
					border-right-color: $primary;
				}

				&::after {
					width: 30px;
					margin-left: 7px;
					background-color: $primary;
				}
			}
		}
	}
}

* + .footer-list-category { margin-top: 20px; }

@media (min-width: 480px) {
	.footer-list-category {
		a {
			padding-right: 160px;

			&:hover {
				letter-spacing: .2em;

				span {
					&::after {
						width: 123px;
					}
				}
			}
		}
	}
}

// Large ≥992px
@include media-breakpoint-up(lg) {
	* + .footer-list-category { margin-top: 8px; }
}

@media (min-width: 1200px) and (max-width: 1399px) {
	.footer-list-category {
		a {
			padding-right: 130px;

			&:hover {
				letter-spacing: .15em;

				span::after {
					width: 90px;
				}
			}
		}

		.heading-5 {
			font-size: 20px;
		}
	}
}



//
// Footer Variant 2
//

.footer-variant-2 {
	text-align: center;
	letter-spacing: .025em;

	.form-label,
	.form-input { text-align: center; }

	.rights {	font-size: 12px; }

	.rd-form {
		* + .button {	margin-top: 10px; }
	}

	* + .rd-form { margin-top: 25px; }
	* + .group-lg { margin-top: 30px; }
	* + .row {margin-top: 24px;}

	* + .footer-contacts {
		margin-top: 22px;
		padding-top: 32px;
		border-top: 1px solid $gray-200;
	}

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		text-align: left;
	}
}

.footer-variant-2-content {
	padding: 50px 0;

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		padding-top: 65px;
		padding-bottom: 65px;
	}
}

.footer-variant-2-bottom-panel {
	padding: 30px 0;
}

// Footer Modern Contacts
.footer-contacts {
	text-align: left;
	font-size: 0;
	line-height: 0;

	.icon {
		vertical-align: middle;
		text-align: left;
		min-width: 21px;
		font-size: 24px;
		line-height: 24px;
		color: $secondary;
	}

	.fa-envelope {font-size: 20px;}
	.fa-clock-o {font-size: 22px;}

	.link-phone {
		font-size: 18px;
		line-height: 1.34;
		letter-spacing: .025em;
	}

	.link-location { max-width: 192px; }

	p {
		font-size: 14px;
		line-height: 1.72;
		letter-spacing: .025em;
	}

	a {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		line-height: 1.72;
		letter-spacing: .025em;
		@include link($gray-800, $primary);
	}

	li + li {	margin-top: 18px; }
}

* + .footer-contacts { margin-top: 28px; }

.footer-social-list-2 {
	.icon { font-size: 16px; }

	a {
		@include link($gray-800, $secondary);
	}
}



//
// Footer Modern
//
.footer-modern {
	background: $gray-23;

	.footer-variant-2-bottom-panel {
		background: $gray-21;
	}
}

//
// Footer Creative
//
.footer-creative {
	background: $gray-25;

	.footer-variant-2-bottom-panel {
		background: $gray-26;
	}
}



// Footer Link List
.footer-link-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	font-size: 12px;
	line-height: 2;
	font-weight: 500;
	@include group(20px, 5px);

	a {
		@include link($gray-800, $secondary);
	}

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		justify-content: flex-start;
	}

	// Medium ≥992px
	@include media-breakpoint-up(lg) {
		justify-content: space-between;
	}
}



//
// Footer Corporate
//

.footer-corporate {
	text-align: center;
	background: $gray-29;

	.footer-contacts {
		.link-phone {
			font-size: 14px;
			line-height: 1.72;
		}

		a { letter-spacing: .05em; }

		.link-location {
			letter-spacing: .025em;
		}

		.unit {
			@include unit-spacing(22px, 10px);
		}
	}

	.rights {	font-size: 12px; }

	.list-marked {
		a {
			@include link(inherit, $primary);
		}
	}

	.button {
		min-width: auto;
		padding-left: 30px;
		padding-right: 30px;
	}

	* + .list-marked { margin-top: 22px; }
	* + .group-md { margin-top: 30px; }

	// Small <576px
	@include media-breakpoint-down(xs) {
		.post-minimal-2 {
			margin-left: auto;
			margin-right: auto;
		}

		.list-marked { text-align: center; }
	}

	// Medium ≥576px
	@include media-breakpoint-up(sm) {
		text-align: left;
	}

	// Medium 768  ≤ 991 Large
	@include media-breakpoint-between(md, md) {
		.footer-contacts {
			@include group(40px, 10px);

			.unit {	align-items: center; }
		}
	}

	// Extra large ≥992px
	@include media-breakpoint-up(lg) {
		.list-marked { max-width: 90%; }
	}
}

.footer-corporate-2 {
	background: $gray-37;

	.footer-corporate-bottom-panel { background: $gray-38; }

	.post-minimal-2-title {
		a {
			@include link($primary, $white);
		}
	}

	.list-custom-2 {
		-moz-column-gap: 20px;
		-webkit-column-gap: 20px;
	}

	// Extra large ≥992px
	@include media-breakpoint-up(lg) {
		.list-marked { max-width: none; }
	}
}

.footer-corporate-inset {
	padding: 50px 0;
	
	// Large ≥992px
	@include media-breakpoint-up(lg) {
		padding: 65px 0;
	}

	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
		padding-top: 75px;
	}
}

.footer-corporate-bottom-panel {
	padding: 30px 0;
	background: $gray-30;
	color: rgba($white, .3);
}



//
// Footer Minimal
//
.footer-minimal {
	background: $gray-11;

	.rights {	font-size: 12px; }
}

.footer-minimal-inset {
	padding: 40px 0;
}

.footer-minimal-bottom-panel {
	padding: 35px 0;
	border-top: 1px solid rgba($white, .13);
}

// Medium ≥768px
@include media-breakpoint-up(md) {
	.footer-minimal-inset { padding: 60px 0; }
}

.footer-list-category-2 {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	text-align: left;
	font-size: 18px;
	line-height: 1.34;
	font-weight: 500;
	text-transform: uppercase;
	font-family: $font-family-sec;

	a {
		@include link($white, $primary);
	}

	// Large <767px
	@include media-breakpoint-down(sm) {
		@include group(30px, 10px);
	}

	// Large ≥992px
	@include media-breakpoint-up(md) {
		justify-content: flex-start;
		@include spacing(52px, 10px);

		> li:not(:first-child) { border-left: 1px solid rgba($white, .2); }
	}
	
	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
		@include spacing(96px, 10px);
	} 
}



.section-prefooter {
	position: relative;

	.google-map { height: 250px; }

	// Medium ≥768px
	@include media-breakpoint-up(md) {
		.google-map-wrap {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.google-map,
		.google-map-container {
			width: inherit;
			height: inherit;
		}
	}
}