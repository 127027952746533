/*
*
* Google Map
*/

.google-map-markers {
	display: none;
}

.google-map-container {
	width: 100%;
}

.google-map {
	height: $map-height;

	@include media-breakpoint-up(md) {
		height: $map-md-height;
	}

	@include media-breakpoint-up(xl) {
		height: $map-xl-height;
	}
}

.section-map-small {
	.google-map {
		@include media-breakpoint-up(lg) {
			height: 460px;
		}
	}
}
