/*
*
* Circle Progress Bars
*/

.progress-bar-circle {
	display: inline-block;
	position: relative;
	text-align: center;
	line-height: 1.2;
	letter-spacing: .05em;
	font-family: $font-family-sec;
	color: $gray-800;
	z-index: 1;

	&::before {
		position: absolute;
		content: '';
		top: 2px;
		right: 2px;
		bottom: 2px;
		left: 2px;
		border: 8px solid $gray-3;
		border-radius: 50%;
		z-index: -1;
	}

	canvas {
		vertical-align: middle;
	}

	span {
		position: absolute;
		top: 53%;
		left: 50%;
		font-size: 48px;
		line-height: 1;
		transform: translate(-50%, -50%);

		&::after {
			font-size: 36px;
			line-height: 1;
			content: "%";
		}
	}
}

.progress-bar-circle-title {
	font-size: 18px;
	letter-spacing: .075em;
	text-transform: uppercase;
	font-family: $font-family-sec;
	color: $gray-800;
}

* + .progress-bar-circle-title { margin-top: 6px; }
