/*
*
* Quotes
*/

//
// Table of Contents: 
//
// Quote Classic
// Quote Modern
// Quote Creative
// Quote Nancy
// Quote Lisa
// Quote Minimal
// Quote Jean
//

//
// Quote Classic
//
.quote-classic {
	position: relative;
	text-align: left;
	padding-top: 35px;
}

.quote-classic-text {
	font-size: 20px;
	line-height: 1.625;
	letter-spacing: .025em;
	
	&::before {
		position: absolute;
		left: 0;
		top: 0;
	  content: '\201C';
		font-size: 70px;
		line-height: 1;
		font-family: $font-family-sec;
		color: $primary;
	}
}

.quote-classic-cite { color: $secondary; }

.quote-classic-big {
	padding-top: 40px;

	.quote-classic-text {
		line-height: 1.5;

		&::before {
			font-size: 90px;
		}
	}
}

* + .quote-classic { margin-top: 22px; }
* + .quote-classic-cite { margin-top: 16px; }
.quote-classic + p { margin-top: 30px; }
.post-classic + .quote-classic { margin-top: 20px; }
.quote-classic + .quote-classic { margin-top: 30px; }

@include media-breakpoint-up(md) {

	.quote-classic {
		padding-top: 0;
		padding-left: 40px;
	}

	.quote-classic-text {
		font-size: 24px;

		&::before {
			top: 2px;
			left: -4px;
			font-size: 91px;
		}
	}

	.quote-classic-big {
		padding-left: 70px;

		.quote-classic-text {
			&::before {
				top: -8px;
				left: 1px;
				font-size: 129px;
			}
		}
	}

	.post-classic + .quote-classic { margin-top: 40px; }
}



//
// Quote Modern
//

.quote-modern {
	position: relative;
	text-align: left;
	padding: 30px 15px;
	letter-spacing: .025em;
	background: $white;
	transform: translate3d(0,0,0);
	backface-visibility: hidden;
	filter: drop-shadow(0 0 2px rgba($black, 0.15));

	&::before {
		position: absolute;
		content: '';
		top: calc(100% - 10px);
		left: 60px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 34px 34px 0 0;
		border-color: $white transparent transparent transparent;
	}
}

.quote-modern-2 {
	.quote-modern-status {
		color: $primary;
	}
}

.quote-modern-figure { display: inline-block; }

.quote-modern-cite {
	font-weight: 400;
	letter-spacing: .075em;
}

.quote-modern-status {
	color: $secondary;
}

* + .quote-modern-status { margin-top: 0; }
* + .quote-modern-text { margin-top: 12px; }

// Medium ≥576px
@include media-breakpoint-up(sm) {
	.quote-modern {
		padding: 40px 20px;
	}
}

// Large ≥992px
@include media-breakpoint-up(lg) {
	.quote-modern {
		padding-left: 30px;
		padding-right: 30px;
	}
}

// Extra large ≥1200px
@include media-breakpoint-up(xl) {
	.quote-modern {padding: 52px 45px 44px;}
	.quote-modern-custom {
		padding-left: 30px;
		padding-right: 30px;
	}
}

// Mega large ≥1600px
@include media-breakpoint-up(xxl) {
	.quote-modern-custom {
		padding-left: 45px;
		padding-right: 45px;
	}
}

.ie-10,
.ie-11,
.ie-edge {
	.quote-modern {
		box-shadow: 0 0 10px 0 rgba($black, 0.17);

		&::before {
			top: 100%;
			border-width: 24px 24px 0 0;
			z-index: 1;
		}

		&::after {
			position: absolute;
			content: '';
			top: calc(100% + 2px);
			left: 58px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 28px 28px 0 0;
			border-color: rgba($black, 0.04) transparent transparent transparent;
			z-index: 0;
		}
	}
}


//
// Quote Creative
//
.quote-creative {
	text-align: center;
	padding: 0 15px 30px;
	letter-spacing: .025em;
	background: $white;
	transform: translate3d(0,0,0);
	backface-visibility: hidden;
	filter: drop-shadow(0 0 2px rgba($black, 0.15));

	.q {
		font-style: italic;
	}
}

.quote-creative-figure {
	display: inline-block;
	margin-top: -42px;
	border: 15px solid $white;
	border-radius: 50%;
}

.quote-creative-cite {
	color: $primary;
	font-weight: 400;

	a {
		@include link(inherit, $secondary);
	}
}

* + .quote-creative-cite { margin-top: 18px; }

// Medium ≥768px
@include media-breakpoint-up(md) {
	.quote-creative {
		padding: 0 20px 40px;
	}
}

.ie-10,
.ie-11,
.ie-edge {
	.quote-creative {
		position: relative;
		background: transparent;
		z-index: 1;
		
		&::before {
			position: absolute;
			content: '';
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: $white;
			box-shadow: 0 0 10px 0 rgba($black, 0.17);
			z-index: -1;
		}
	}

	.quote-creative-figure {
		position: relative;

		&::before {
		  position: absolute;
		  content: '';
		  top: -16px;
		  right: -16px;
		  bottom: -16px;
		  left: -16px;
			border-radius: 50%;
			box-shadow: 0 0 10px 0 rgba($black, 0.17);
			z-index: -2;
		}
	}
}


//
// Quote Nancy
//
.quote-nancy {
	position: relative;
	text-align: left;
	letter-spacing: .025em;
	perspective: 1000px;
}

.quote-nancy-content {
	transform-style: preserve-3d;
	transition: all .5s ease;
}

.quote-nancy-body {
	position: relative;
	padding: 40px 20px 30px;
	background: $gray-4;
	will-change: transform;
	overflow: hidden;
	backface-visibility: hidden;
	clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 0);
	z-index: 1;
}

.quote-nancy-figure {
	position: absolute;
	content: '';
	top: 1px;
	right: 1px;
	bottom: 1px;
	left: 1px;
	overflow: hidden;
	clip-path: polygon(100% 0, 100% 90%, 90% 100%, 0 100%, 0 0);

	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-height: 100%;
		min-width: 100%;
		width: auto;
		height: auto;
		max-width: none;

		@supports(object-fit: cover) {
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			transform: none;
			object-fit: cover;
			object-position: center center;
		}
	}
}

.quote-nancy-quote {
	position: relative;
	padding-top: 35px;

	h4 {
		line-height: 1.2;
		font-weight: 300;
		letter-spacing: .05em;
	}

	&::before {
		position: absolute;
		left: 0;
		top: 0;
		content: '\201C';
		font-size: 70px;
		line-height: 1;
		font-family: $font-family-sec;
		color: $primary;
	}
}

.quote-nancy-cite,
.quote-nancy-author {
	font-weight: 400;
}

.quote-nancy-status {
	color: $gray-500;
}

.desktop {
	// Large ≥992px
	@include media-breakpoint-up(lg) {
		.quote-nancy:hover .quote-nancy-content {
			transform: rotateY(180deg);
		} 
	}
}

@include media-breakpoint-up(md) {

	.quote-nancy-quote {
		padding-top: 0;
		padding-left: 40px;
	}

	.quote-nancy-quote {
		&::before {
			top: -3px;
			left: 0;
			font-size: 91px;
		}
	}
}

// Medium 768  ≤ 991 Large
@include media-breakpoint-between(md, md) {
  .owl-quote {
		.quote-nancy-quote h4 {	font-size: 34px; }
	}
}

// Large ≥992px
@include media-breakpoint-up(lg) {
	.quote-nancy-body {
		padding: 60px 30px 40px;
	}
}

* + .quote-nancy-status { margin-top: 0; }
* + .unit { margin-top: 20px; }

.ie-10,
.ie-11,
.ie-edge {
	.quote-nancy-body,
	.quote-nancy-figure {
		&::before {
			position: absolute;
			content: '';
			top: 0;
			right: 0;
			bottom: 0;
			width: 44px;
			background: $white;
			transform: skew(-45deg);
			transform-origin: 100% 100%;
			z-index: 1;
		}
	}

	.quote-nancy-figure {
		&::before {
			z-index: 1;
		}
	}

	.quote-nancy-body {
		transition: opacity .05s ease .15s;
	}
	
	&.desktop {
		// Large ≥992px
		@include media-breakpoint-up(lg) {
			.quote-nancy:hover .quote-nancy-content {
				transform: rotateY(180deg);
			}
			.quote-nancy:hover .quote-nancy-body {
				opacity: 0;
			}
		}
	}
}



//
// Quote Lisa
//

.quote-lisa {
	text-align: center;
	padding: 30px 10px;
	letter-spacing: .025em;
	background: $white;
	box-shadow: 2px 3px 6px 0 rgba($black, 0.17);
}

.quote-lisa-2 {
	.quote-lisa-text {
		&::before,
		&::after {color: $primary;}
	}

	.quote-lisa-cite {
		a:hover { color: $primary; }
	}
	.quote-lisa-status {color: $secondary;}
}

.quote-lisa-body {
	max-width: 880px;
	margin-left: auto;
	margin-right: auto;
}

.quote-lisa-text {
	position: relative;
	padding-left: 20px;
	padding-right: 20px;

	&::before,
	&::after {
		position: absolute;
		font-size: 48px;
		line-height: 1;
		font-family: $font-family-sec;
		color: $secondary;
	}

	&::before {
		left: 0;
		top: 4px;
		content: '\201C';
	}

	&::after {
		right: 0;
		bottom: -24px;
		content: '\201D';
	}
}

.quote-lisa-cite {
	font-weight: 400;
	letter-spacing: .075em;
	
	a:hover {
		color: $secondary;
	}
}

.quote-lisa-status {
	color: $primary;
}

* + .quote-lisa-status { margin-top: 0; }
* + .quote-lisa-text { margin-top: 18px; }
* + .quote-lisa-cite { margin-top: 26px; }

// Medium ≥576px
@include media-breakpoint-up(sm) {
	.quote-lisa {
		padding-left: 15px;
		padding-right: 15px;
	}

	.quote-lisa-text {
		padding-left: 30px;
		padding-right: 30px;
	}
}

// Large ≥992px
@include media-breakpoint-up(lg) {
	.quote-lisa {
		padding-top: 60px;
		padding-bottom: 60px;
	}
}



	//
// Quote Minimal
//

.quote-minimal {
	position: relative;
	text-align: left;
}

.quote-minimal-text {
	padding-left: 30px;
	font-size: 16px;
	line-height: 1.5;
	letter-spacing: .025em;

	&::before {
		position: absolute;
		left: 0;
		top: 4px;
		content: '\201C';
		font-size: 48px;
		line-height: 1;
		font-family: $font-family-sec;
		color: $primary;
	}
}

.quote-minimal-cite {
	line-height: 1.33;
	font-weight: 400;
	color: $gray-500;
}

.quote-minimal-source {
	display: block;
	a { @include link($primary, $secondary); }
}

* + .quote-minimal { margin-top: 22px; }
* + .quote-minimal-cite { margin-top: 20px; }
.quote-minimal + .quote-minimal { margin-top: 30px; }



//
// Quote Jean
//
.quote-jean {
	position: relative;
	max-width: 520px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 10px;
	background-image: url(../images/quote-jean.png);
	background-repeat: no-repeat;
	background-position: 50% 0;
}

* + .quote-jean {	margin-top: 16px; }
.quote-jean + .isotope-filters {	margin-top: 35px; }